import React from "react";

export default function Header() {
  return (
    <>
      <div className="bg-gray-300 font-bold text-xl text-black p-5 text-center">
        Connecting Blood
      </div>
    </>
  );
}
