import { FaFacebook, FaInstagram, FaLinkedin, FaYoutube } from "react-icons/fa";
import Logo from "../assets/images/logo.png";
import X from "../assets/images/twitter.png";

export default function UnderConstruction() {
  return (
    <div className="min-h-screen bg-[#C2E2F5] flex flex-col items-center justify-center p-4">
      <div className="max-w-2xl w-full text-center space-y-8">
        <div className="mb-8 flex items-center justify-center space-x-1">
          <img
            src={Logo}
            alt="Connecting Blood Logo"
            width={120}
            height={120}
            className="w-auto h-12 md:h-16"
          />
          <h1 className="text-3xl md:text-4xl text-red-700 font-bold tracking-tighter">
            CONNECTING BLOOD
          </h1>
        </div>

        <h2 className="text-3xl md:text-4xl font-bold mb-4">
          WEBSITE UNDER CONSTRUCTION
        </h2>

        <div className="space-y-4">
          <h3 className="text-2xl font-semibold mb-6">Follow us on</h3>
          <div className="flex flex-wrap justify-center gap-4">
            <a
              href="https://tinyurl.com/yc8p2ks6"
              className="flex items-center gap-2 bg-white px-6 py-3 rounded-full shadow-md hover:shadow-lg transition-all duration-300 hover:-translate-y-1"
            >
              <FaLinkedin className="h-5 w-5 text-blue-600" />
              <span className="font-medium">LinkedIn</span>
            </a>
            <a
              href="https://tinyurl.com/45wc4jzu"
              className="flex items-center gap-2 bg-white px-6 py-3 rounded-full shadow-md hover:shadow-lg transition-all duration-300 hover:-translate-y-1"
            >
              <FaInstagram className="h-5 w-5 text-pink-600" />
              <span className="font-medium">Instagram</span>
            </a>
            <a
              href="https://tinyurl.com/mr3fb2vf"
              className="flex items-center gap-2 bg-white px-6 py-3 rounded-full shadow-md hover:shadow-lg transition-all duration-300 hover:-translate-y-1"
            >
              <img src={X} alt="Twitter Logo" className="h-5 w-5" />
              <span className="font-medium">Twitter</span>
            </a>
            <a
              href="https://tinyurl.com/22ttwxuu"
              className="flex items-center gap-2 bg-white px-6 py-3 rounded-full shadow-md hover:shadow-lg transition-all duration-300 hover:-translate-y-1"
            >
              <FaFacebook className="h-5 w-5 text-blue-800" />
              <span className="font-medium">Facebook</span>
            </a>
            <a
              href="https://tinyurl.com/ye26nj3r"
              className="flex items-center gap-2 bg-white px-6 py-3 rounded-full shadow-md hover:shadow-lg transition-all duration-300 hover:-translate-y-1"
            >
              <FaYoutube className="h-5 w-5 text-red-600" />
              <span className="font-medium">YouTube</span>
            </a>
          </div>
        </div>

        <div className="mt-12 text-gray-600">
          <p>© 2024 Connecting Blood. All rights reserved.</p>
        </div>
      </div>
    </div>
  );
}
