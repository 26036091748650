import { Route, Routes } from "react-router-dom";
import ProtectedRoute from "./Routes/PrivateRoute";
import { routeNames } from "./Routes/RouteSegments";
import Home from "./Pages/Home";
import Login from "./Pages/Login";
import UnderConstruction from "./Pages/UnderConstruction";
import Signup from "./components/Login/Signup";

function App() {
  return (
    <>
      <Routes>
        {/* Temporary route for Under Construction */}
        <Route
          path={routeNames.underConstruction}
          element={<UnderConstruction />}
        />

        {/* Default route redirecting to Under Construction for now */}
        <Route path="/" element={<UnderConstruction />} />

        <Route
          path={routeNames.home}
          element={
            <ProtectedRoute>
              <Home />
            </ProtectedRoute>
          }
        />
        <Route path={routeNames.login} element={<Login />} />
        <Route path={routeNames.signup} element={<Signup />} />
      </Routes>
    </>
  );
}

export default App;
