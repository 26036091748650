import React, { useState, useEffect, useRef } from "react";

export default function Signup() {
  const [step, setStep] = useState(1);
  const [timer, setTimer] = useState(300);
  const [resendDisabled, setResendDisabled] = useState(true);
  const [showOtpInput, setShowOtpInput] = useState(false);
  const [formData, setFormData] = useState({
    emailOrPhone: "",
    firstName: "",
    lastName: "",
    gender: "",
    dob: "",
    address: "",
    state: "",
  });
  const [otp, setOtp] = useState(["", "", "", "", "", ""]);
  const [errors, setErrors] = useState({});

  const otpRefs = useRef([]);

  useEffect(() => {
    let countdown;
    if (step === 1 && timer > 0 && showOtpInput) {
      countdown = setInterval(() => {
        setTimer((prev) => {
          if (prev <= 1) {
            clearInterval(countdown);
            setResendDisabled(false);
            return 0;
          }
          return prev - 1;
        });
      }, 1000);
    }
    return () => clearInterval(countdown);
  }, [timer, step, showOtpInput]);

  const validateEmailOrPhone = () => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.(com|net|org|edu|gov|co|in)$/i;
    const phoneRegex = /^[0-9]{10}$/;
    if (!formData.emailOrPhone) {
      return "Please enter your email address or phone number.";
    }
    if (emailRegex.test(formData.emailOrPhone)) {
      return "";
    } else if (phoneRegex.test(formData.emailOrPhone)) {
      return "";
    } else {
      return "Please enter a valid email address or 10-digit phone number.";
    }
  };

  const handleSendOtp = () => {
    const emailOrPhoneError = validateEmailOrPhone();
    if (emailOrPhoneError) {
      setErrors({ emailOrPhone: emailOrPhoneError });
    } else {
      setErrors({});
      setShowOtpInput(true);
      setTimer(300);
      setResendDisabled(true);
      // Logic to send OTP
    }
  };

  const handleResendCode = () => {
    setTimer(300);
    setResendDisabled(true);
    // Logic to resend code
  };

  const handleNext = () => {
    if (step === 1) {
      const step1Errors = validateStep1();
      if (Object.keys(step1Errors).length > 0) {
        setErrors(step1Errors);
      } else {
        setErrors({});
        setStep(2);
      }
    }
  };

  const handleOtpChange = (e, index) => {
    const value = e.target.value.replace(/[^0-9]/g, "");
    if (value.length <= 1) {
      const newOtp = [...otp];
      newOtp[index] = value;
      setOtp(newOtp);
      if (value && index < otpRefs.current.length - 1) {
        otpRefs.current[index + 1].focus();
      }
    }
  };

  const handleOtpBackspace = (e, index) => {
    if (e.key === "Backspace" && otp[index] === "" && index > 0) {
      otpRefs.current[index - 1].focus();
    }
  };

  const handleContinue = () => {
    const step2Errors = validateStep2();
    if (Object.keys(step2Errors).length > 0) {
      setErrors(step2Errors);
    } else {
      setErrors({});
      setStep(3);
    }
  };

  const validateStep1 = () => {
    let errors = {};
    const emailOrPhoneError = validateEmailOrPhone();
    if (emailOrPhoneError) errors.emailOrPhone = emailOrPhoneError;
    if (!showOtpInput)
      errors.otp = "Please send and verify OTP before proceeding.";
    else if (otp.join("").length !== 6)
      errors.otp = "Please enter the complete 6-digit OTP.";
    return errors;
  };

  const validateStep2 = () => {
    let errors = {};
    if (!formData.gender) errors.gender = "Please select your gender.";
    if (!formData.firstName) errors.firstName = "Please enter your first name.";
    if (!formData.lastName) errors.lastName = "Please enter your last name.";
    if (!formData.dob) errors.dob = "Please enter your date of birth.";
    if (!formData.address) errors.address = "Please enter your address.";
    if (!formData.state) errors.state = "Please select your state.";
    return errors;
  };

  const isOtpComplete = otp.every((digit) => digit !== "");

  const generateYearOptions = () => {
    const currentYear = new Date().getFullYear();
    const years = [];
    for (let year = currentYear; year >= 1900; year--) {
      years.push(
        <option key={year} value={year}>
          {year}
        </option>
      );
    }
    return years;
  };

  const indianStates = [
    "Andhra Pradesh",
    "Arunachal Pradesh",
    "Assam",
    "Bihar",
    "Chhattisgarh",
    "Goa",
    "Gujarat",
    "Haryana",
    "Himachal Pradesh",
    "Jharkhand",
    "Karnataka",
    "Kerala",
    "Madhya Pradesh",
    "Maharashtra",
    "Manipur",
    "Meghalaya",
    "Mizoram",
    "Nagaland",
    "Odisha",
    "Punjab",
    "Rajasthan",
    "Sikkim",
    "Tamil Nadu",
    "Telangana",
    "Tripura",
    "Uttar Pradesh",
    "Uttarakhand",
    "West Bengal",
    "Andaman and Nicobar Islands",
    "Chandigarh",
    "Dadra and Nagar Haveli and Daman and Diu",
    "Delhi",
    "Jammu and Kashmir",
    "Ladakh",
    "Lakshadweep",
    "Puducherry",
  ];

  const renderStep = () => {
    switch (step) {
      case 1:
        return (
          <div className="bg-gradient-to-r from-red-500 to-pink-500 p-8 rounded-lg shadow-md max-w-md w-full">
            <h1 className="font-bold text-2xl mb-6 text-white text-center">
              Verify your account
            </h1>
            <div className="space-y-4">
              <div>
                <label className="block text-sm font-medium text-white mb-1">
                  Email or phone number
                </label>
                <input
                  type="text"
                  placeholder="Enter your email or phone"
                  className="w-full px-3 py-2 bg-white rounded-md text-gray-700 focus:outline-none focus:ring-2 focus:ring-red-300"
                  value={formData.emailOrPhone}
                  onChange={(e) =>
                    setFormData({ ...formData, emailOrPhone: e.target.value })
                  }
                />
                {errors.emailOrPhone && (
                  <p className="text-yellow-200 text-xs mt-1">
                    {errors.emailOrPhone}
                  </p>
                )}
              </div>
              <button
                className="w-full bg-white text-red-500 font-bold py-2 px-4 rounded-md hover:bg-gray-100 transition duration-300"
                onClick={handleSendOtp}
              >
                Send OTP
              </button>
              {showOtpInput && (
                <>
                  <div>
                    <h2 className="font-bold text-lg text-white mb-2">
                      6-digit code
                    </h2>
                    <p className="text-sm text-white mb-3">
                      Enter the code we sent to your email or phone.
                    </p>
                    <div className="flex justify-between space-x-2">
                      {[...Array(6)].map((_, i) => (
                        <input
                          key={i}
                          type="text"
                          maxLength={1}
                          className="w-10 h-10 text-center text-lg border border-gray-300 rounded bg-white text-gray-700"
                          value={otp[i]}
                          onChange={(e) => handleOtpChange(e, i)}
                          onKeyDown={(e) => handleOtpBackspace(e, i)}
                          ref={(el) => (otpRefs.current[i] = el)}
                        />
                      ))}
                    </div>
                    {errors.otp && (
                      <p className="text-yellow-200 text-xs mt-1">
                        {errors.otp}
                      </p>
                    )}
                  </div>
                  <p className="text-sm text-white">
                    Code expires in{" "}
                    {`${Math.floor(timer / 60)}:${(timer % 60)
                      .toString()
                      .padStart(2, "0")}`}
                    s
                  </p>
                  <button
                    className={`w-full ${
                      resendDisabled
                        ? "bg-gray-400 cursor-not-allowed"
                        : "bg-white hover:bg-gray-100"
                    } text-red-500 font-bold py-2 px-4 rounded-md transition duration-300`}
                    onClick={handleResendCode}
                    disabled={resendDisabled}
                  >
                    Resend OTP
                  </button>
                </>
              )}
              {isOtpComplete && (
                <button
                  className="w-full bg-white text-red-500 font-bold py-2 px-4 rounded-md hover:bg-gray-100 transition duration-300"
                  onClick={handleNext}
                >
                  Verify
                </button>
              )}
            </div>
          </div>
        );
      case 2:
        return (
          <div className="bg-white p-8 rounded-lg shadow-md max-w-md w-full">
            <h1 className="font-bold text-2xl mb-6 text-red-500 text-center">
              Tell us about yourself
            </h1>
            <div className="space-y-4">
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Gender
                </label>
                <select
                  className="w-full px-3 py-2 bg-gray-100 rounded-md text-gray-700 focus:outline-none focus:ring-2 focus:ring-red-300"
                  value={formData.gender}
                  onChange={(e) =>
                    setFormData({ ...formData, gender: e.target.value })
                  }
                >
                  <option value="">Select Gender</option>
                  <option value="Male">Male</option>
                  <option value="Female">Female</option>
                </select>
                {errors.gender && (
                  <p className="text-red-500 text-xs mt-1">{errors.gender}</p>
                )}
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  First Name
                </label>
                <input
                  type="text"
                  className="w-full px-3 py-2 bg-gray-100 rounded-md text-gray-700 focus:outline-none focus:ring-2 focus:ring-red-300"
                  value={formData.firstName}
                  onChange={(e) =>
                    setFormData({ ...formData, firstName: e.target.value })
                  }
                />
                {errors.firstName && (
                  <p className="text-red-500 text-xs mt-1">
                    {errors.firstName}
                  </p>
                )}
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Last Name
                </label>
                <input
                  type="text"
                  className="w-full px-3 py-2 bg-gray-100 rounded-md text-gray-700 focus:outline-none focus:ring-2 focus:ring-red-300"
                  value={formData.lastName}
                  onChange={(e) =>
                    setFormData({ ...formData, lastName: e.target.value })
                  }
                />
                {errors.lastName && (
                  <p className="text-red-500 text-xs mt-1">{errors.lastName}</p>
                )}
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Date of Birth
                </label>
                <div className="flex space-x-2">
                  <select
                    className="w-1/3 px-3 py-2 bg-gray-100 rounded-md text-gray-700 focus:outline-none focus:ring-2 focus:ring-red-300"
                    value={formData.dob.split("-")[2] || ""}
                    onChange={(e) => {
                      const [year, month] = formData.dob.split("-");
                      setFormData({
                        ...formData,
                        dob: `${year}-${month}-${e.target.value.padStart(
                          2,
                          "0"
                        )}`,
                      });
                    }}
                  >
                    <option value="">Day</option>
                    {[...Array(31)].map((_, i) => (
                      <option key={i + 1} value={i + 1}>
                        {i + 1}
                      </option>
                    ))}
                  </select>
                  <select
                    className="w-1/3 px-3 py-2 bg-gray-100 rounded-md text-gray-700 focus:outline-none focus:ring-2 focus:ring-red-300"
                    value={formData.dob.split("-")[1] || ""}
                    onChange={(e) => {
                      const [year, , day] = formData.dob.split("-");
                      setFormData({
                        ...formData,
                        dob: `${year}-${e.target.value.padStart(
                          2,
                          "0"
                        )}-${day}`,
                      });
                    }}
                  >
                    <option value="">Month</option>
                    {[...Array(12)].map((_, i) => (
                      <option key={i + 1} value={i + 1}>
                        {new Date(0, i).toLocaleString("default", {
                          month: "long",
                        })}
                      </option>
                    ))}
                  </select>
                  <select
                    className="w-1/3 px-3 py-2 bg-gray-100 rounded-md text-gray-700 focus:outline-none focus:ring-2 focus:ring-red-300"
                    value={formData.dob.split("-")[0] || ""}
                    onChange={(e) => {
                      const [, month, day] = formData.dob.split("-");

                      setFormData({
                        ...formData,
                        dob: `${e.target.value}-${month}-${day}`,
                      });
                    }}
                  >
                    <option value="">Year</option>
                    {generateYearOptions()}
                  </select>
                </div>
                {errors.dob && (
                  <p className="text-red-500 text-xs mt-1">{errors.dob}</p>
                )}
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Address
                </label>
                <input
                  type="text"
                  className="w-full px-3 py-2 bg-gray-100 rounded-md text-gray-700 focus:outline-none focus:ring-2 focus:ring-red-300"
                  value={formData.address}
                  onChange={(e) =>
                    setFormData({ ...formData, address: e.target.value })
                  }
                />
                {errors.address && (
                  <p className="text-red-500 text-xs mt-1">{errors.address}</p>
                )}
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  State
                </label>
                <select
                  className="w-full px-3 py-2 bg-gray-100 rounded-md text-gray-700 focus:outline-none focus:ring-2 focus:ring-red-300"
                  value={formData.state}
                  onChange={(e) =>
                    setFormData({ ...formData, state: e.target.value })
                  }
                >
                  <option value="">Select State</option>
                  {indianStates.map((state) => (
                    <option key={state} value={state}>
                      {state}
                    </option>
                  ))}
                </select>
                {errors.state && (
                  <p className="text-red-500 text-xs mt-1">{errors.state}</p>
                )}
              </div>
              <button
                className="w-full bg-red-500 text-white font-bold py-2 px-4 rounded-md hover:bg-red-600 transition duration-300"
                onClick={handleContinue}
              >
                Continue
              </button>
            </div>
          </div>
        );
      case 3:
        return (
          <div className="bg-white p-8 rounded-lg shadow-md max-w-md w-full text-center">
            <h1 className="font-bold text-2xl mb-6 text-red-500">
              Thank you for registering!
            </h1>
            <p className="text-gray-600 mb-6">
              You can now log in using your credentials.
            </p>
            <button
              className="w-full bg-red-500 text-white font-bold py-2 px-4 rounded-md hover:bg-red-600 transition duration-300"
              onClick={() => setStep(1)}
            >
              Back to Login
            </button>
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <div className="min-h-screen bg-gray-100 flex items-center justify-center px-4 sm:px-6 lg:px-8">
      {renderStep()}
    </div>
  );
}
