import React from "react";
import { Navigate } from "react-router-dom";
import { routeNames } from "./RouteSegments";

/**
 * The ProtectedRoute function is a component that restrict already logged in user to visit some pages.
 */
function ProtectedRoute({ children }) {
  const isLoggedIn = true;
  if (isLoggedIn === false) {
    return <Navigate to={routeNames.login} />;
  }
  return children;
}

export default ProtectedRoute;
