import React from "react";
import Header from "../components/Common/Header";
import Footer from "../components/Common/Footer";

export default function Home() {
  return (
    <div className="flex flex-col min-h-screen">
      <Header />

      <div className="flex-grow flex items-center justify-center">
        <h1 className="text-2xl">Welcome to Connecting Blood!</h1>
      </div>

      <Footer />
    </div>
  );
}
