"use client";

import React, { useState } from "react";
import { FaFacebookF, FaGoogle, FaLinkedinIn } from "react-icons/fa";

export default function SliderLogin() {
  const [isRightPanelActive, setIsRightPanelActive] = useState(true); // Set to true initially for "Create Account"

  const toggleRightPanel = () => {
    setIsRightPanelActive(!isRightPanelActive);
  };

  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-gray-100 font-montserrat">
      <div
        className={`relative overflow-hidden w-full max-w-[768px] min-h-[480px] bg-white rounded-lg shadow-2xl ${
          isRightPanelActive ? "right-panel-active" : ""
        }`}
      >
        {isRightPanelActive ? (
          <div
            className={`absolute top-0 h-full transition-all duration-600 ease-in-out left-0 w-1/2 opacity-0 z-1 ${
              isRightPanelActive
                ? "translate-x-full opacity-100 z-5 animate-show"
                : ""
            }`}
          >
            <form className="bg-white flex items-center justify-center flex-col px-10 h-full">
              <h1 className="font-bold text-2xl mb-3">Create Account</h1>
              <div className="my-5 flex">
                <a
                  href="#"
                  className="border border-gray-300 rounded-full w-10 h-10 flex items-center justify-center mx-1.5"
                >
                  <FaFacebookF className="text-sm" />
                </a>
                <a
                  href="#"
                  className="border border-gray-300 rounded-full w-10 h-10 flex items-center justify-center mx-1.5"
                >
                  <FaGoogle className="text-sm" />
                </a>
                <a
                  href="#"
                  className="border border-gray-300 rounded-full w-10 h-10 flex items-center justify-center mx-1.5"
                >
                  <FaLinkedinIn className="text-sm" />
                </a>
              </div>
              <span className="text-xs mb-3">
                or use your email for registration
              </span>
              <input
                type="text"
                placeholder="Name"
                className="bg-gray-100 border-none px-4 py-2 my-2 w-full"
              />
              <input
                type="email"
                placeholder="Email"
                className="bg-gray-100 border-none px-4 py-2 my-2 w-full"
              />
              <input
                type="password"
                placeholder="Password"
                className="bg-gray-100 border-none px-4 py-2 my-2 w-full"
              />
              <button className="mt-3 border rounded-full border-red-500 bg-red-500 text-white text-xs font-bold py-3 px-12 uppercase tracking-wide transition duration-80 ease-in hover:bg-red-600 focus:outline-none">
                Sign Up
              </button>
            </form>
          </div>
        ) : (
          <div
            className={`absolute top-0 h-full transition-all duration-600 ease-in-out left-0 w-1/2 z-2 ${
              isRightPanelActive ? "translate-x-full" : ""
            }`}
          >
            <form className="bg-white flex items-center justify-center flex-col px-10 h-full">
              <h1 className="font-bold text-2xl mb-3">Sign in</h1>
              <div className="my-5 flex">
                <a
                  href="#"
                  className="border border-gray-300 rounded-full w-10 h-10 flex items-center justify-center mx-1.5"
                >
                  <FaFacebookF className="text-sm" />
                </a>
                <a
                  href="#"
                  className="border border-gray-300 rounded-full w-10 h-10 flex items-center justify-center mx-1.5"
                >
                  <FaGoogle className="text-sm" />
                </a>
                <a
                  href="#"
                  className="border border-gray-300 rounded-full w-10 h-10 flex items-center justify-center mx-1.5"
                >
                  <FaLinkedinIn className="text-sm" />
                </a>
              </div>
              <span className="text-xs mb-3">or use your account</span>
              <input
                type="email"
                placeholder="Email"
                className="bg-gray-100 border-none px-4 py-2 my-2 w-full"
              />
              <input
                type="password"
                placeholder="Password"
                className="bg-gray-100 border-none px-4 py-2 my-2 w-full"
              />
              <a href="#" className="text-sm my-3">
                Forgot your password?
              </a>
              <button className="mt-3 border rounded-full border-red-500 bg-red-500 text-white text-xs font-bold py-3 px-12 uppercase tracking-wide transition duration-80 ease-in hover:bg-red-600 focus:outline-none">
                Sign In
              </button>
            </form>
          </div>
        )}
        <div
          className={`absolute top-0 left-1/2 w-1/2 h-full overflow-hidden transition transform duration-600 ease-in-out ${
            isRightPanelActive ? "-translate-x-full" : ""
          }`}
        >
          <div
            className={`bg-gradient-to-r from-red-500 to-pink-500 text-white relative -left-full h-full w-[200%] transform ${
              isRightPanelActive ? "translate-x-1/2" : ""
            } transition duration-600 ease-in-out`}
          >
            <div
              className={`absolute flex items-center justify-center flex-col px-10 text-center top-0 h-full w-1/2 transform ${
                isRightPanelActive ? "translate-x-0" : "-translate-x-[20%]"
              } transition duration-600 ease-in-out`}
            >
              <h1 className="font-bold text-2xl mb-3">Welcome Back!</h1>
              <p className="text-sm leading-5 tracking-wide mb-8">
                To keep connected with us please login with your personal info
              </p>
              <button
                className="bg-transparent border border-white rounded-full text-white text-xs font-bold py-3 px-12 uppercase tracking-wide transition duration-80 ease-in hover:bg-white hover:text-red-500 focus:outline-none"
                onClick={toggleRightPanel}
              >
                Sign In
              </button>
            </div>
            <div
              className={`absolute flex items-center justify-center flex-col px-10 text-center top-0 right-0 h-full w-1/2 transform ${
                isRightPanelActive ? "translate-x-[20%]" : ""
              } transition duration-600 ease-in-out`}
            >
              <h1 className="font-bold text-2xl mb-3">Hello, Friend!</h1>
              <p className="text-sm leading-5 tracking-wide mb-8">
                Enter your personal details and start journey with us
              </p>
              <button
                className="bg-transparent border border-white rounded-full text-white text-xs font-bold py-3 px-12 uppercase tracking-wide transition duration-80 ease-in hover:bg-white hover:text-red-500 focus:outline-none"
                onClick={toggleRightPanel}
              >
                Sign Up
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
