import React from "react";
import { FaLinkedin, FaFacebook, FaYoutube, FaInstagram } from "react-icons/fa";

export default function Footer() {
  return (
    <footer className="bg-blue-500 text-white p-5">
      <div className="container mx-auto flex flex-col md:flex-row justify-between items-center">
        {/* Footer Links */}
        <div className="mb-4 md:mb-0">
          <ul className="flex flex-wrap justify-center md:justify-start space-x-4">
            <ul className="flex flex-wrap space-x-4">
              <li>
                <a href="#" className="hover:underline">
                  Home
                </a>
              </li>
              <li>
                <a href="#" className="hover:underline">
                  Contact Us
                </a>
              </li>
              <li>
                <a href="#" className="hover:underline">
                  About Us
                </a>
              </li>
              <li>
                <a href="#" className="hover:underline">
                  Terms of Use
                </a>
              </li>
              <li>
                <a href="#" className="hover:underline">
                  Privacy Policy
                </a>
              </li>
              <li>
                <a href="#" className="hover:underline">
                  Supporters
                </a>
              </li>
              <li>
                <a href="#" className="hover:underline">
                  Blogs
                </a>
              </li>
              <li>
                <a href="#" className="hover:underline">
                  FAQs
                </a>
              </li>
              <li>
                <a href="#" className="hover:underline">
                  News
                </a>
              </li>
            </ul>
          </ul>
        </div>
        <div className="flex space-x-4">
          <a href="https://linkedin.com" className="hover:text-gray-400">
            <FaLinkedin size={24} />
          </a>
          <a href="https://facebook.com" className="hover:text-gray-400">
            <FaFacebook size={24} />
          </a>
          <a href="https://youtube.com" className="hover:text-gray-400">
            <FaYoutube size={24} />
          </a>
          <a href="https://instagram.com" className="hover:text-gray-400">
            <FaInstagram size={24} />
          </a>
        </div>
      </div>
    </footer>
  );
}
